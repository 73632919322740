import { Divider, Stack } from '@mui/material';
import { Radio, RadioGroup, Typography } from '@platform-ui/design-system';
import React, { FC, useEffect, useState } from 'react';
import { RepeatsDailyProps } from '../interfaces';
import ScheduleEvery from './ScheduleEvery';
import ScheduleTime from './ScheduleTime';

const Daily: FC<RepeatsDailyProps> = (props) => {
  const [daily, setDaily] = useState({
    every: '1',
    hour: '00',
    minute: '00',
    selected: '0'
  });

  useEffect(() => {
    props.onChange(['0', '00', '00', `${props.workflowCronFormat ? '' : '1'}/1`, '*', '*']);
  }, []);

  return (
    <>
      <RadioGroup
        label={null}
        value={daily.selected}
        dsOnChange={(e) => {
          setDaily({ ...daily, selected: e.target.value });
          let cronExpression: string[];
          if (e.target.value === '0') {
            cronExpression = ['0', daily.minute, daily.hour, `${props.workflowCronFormat ? '' : '1'}/${daily.every}`, '*', '*'];
          } else {
            cronExpression = ['0', daily.minute, daily.hour, '*', '*', 'MON-FRI'];
          }
          props.onChange(cronExpression);
        }}
      >
        <Stack spacing={0}>
          <Radio
            label={
              <ScheduleEvery
                frontLabel='Every'
                backLabel='day(s)'
                value={daily.every}
                onChange={(e) => {
                  setDaily({ ...daily, every: e.target.value });
                  const cronExpression = ['0', daily.minute, daily.hour, `${props.workflowCronFormat ? '' : '1'}/${e.target.value}`, '*', '*'];
                  props.onChange(cronExpression);
                }}
                disabled={daily.selected !== '0'}
                type='number'
                error={Number.isNaN(parseInt(daily.every)) || parseInt(daily.every) < 1}
              />
            }
            value={0}
            e2e='schedule-radio-top'
          />
          <Radio
            label={
              <Typography variant='body2' className='dark-text'>
                Every weekday
              </Typography>
            }
            value={1}
          />
        </Stack>
      </RadioGroup>
      <Divider sx={{ margin: '16px 0' }} />
      <ScheduleTime
        label='Start time'
        hourly={daily}
        onChange={(e, isHour) => {
          let cronExpression: string[];
          if (isHour) {
            setDaily({ ...daily, hour: e.target.value });
            if (daily.selected === '0') {
              cronExpression = ['0', daily.minute, e.target.value, `${props.workflowCronFormat ? '' : '1'}/${daily.every}`, '*', '*'];
            } else {
              cronExpression = ['0', daily.minute, e.target.value, '*', '*', 'MON-FRI'];
            }
          } else {
            setDaily({ ...daily, minute: e.target.value });
            if (daily.selected === '0') {
              cronExpression = ['0', e.target.value, daily.hour, `${props.workflowCronFormat ? '' : '1'}/${daily.every}`, '*', '*'];
            } else {
              cronExpression = ['0', e.target.value, daily.hour, '*', '*', 'MON-FRI'];
            }
          }
          props.onChange(cronExpression);
        }}
        repeats='daily'
        disabled={false}
      />
    </>
  );
};

export default Daily;