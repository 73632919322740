import React, { FC } from 'react';
import { ImmerReducer, useImmerReducer } from 'use-immer';
import { StoreContext } from './context';
import { Action, State, StoreProps } from './types';

export const createStore = (reducer: ImmerReducer<State, Action>) => {
  const Store: FC<StoreProps> = ({ initialState, children }: StoreProps) => {
    const [state, dispatch] = useImmerReducer<State, Action>(reducer, initialState);
    return (
      <StoreContext.Provider value={{state, dispatch}}>
        {children}
        </StoreContext.Provider>
    );
  };

  return Store;
};