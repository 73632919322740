import { Stack } from '@mui/material';
import { Typography } from '@platform-ui/design-system';
import React, { FC } from 'react';
import ScheduleTextField from './ScheduleTextField';

interface ScheduleEveryProps {
  frontLabel: string;
  backLabel: string;
  value: string;
  onChange: (e: { target: { value: string } }) => void;
  disabled: boolean;
  error: boolean;
  type?: string;
  placeholder?: string;
}

const ScheduleEvery: FC<ScheduleEveryProps> = (props) => {
  return (
    <Stack spacing={1} direction='row' alignItems='center'>
      <Typography variant='body2' className='dark-text'>
        {props.frontLabel}
      </Typography>
      <ScheduleTextField
        value={props.value}
        onChange={(e) => props.onChange(e)}
        disabled={props.disabled}
        placeholder={props.placeholder || ''}
        type={props.type || 'number'}
        error={props.error}
      />
      <Typography variant='body2' className='dark-text'>
        {props.backLabel}
      </Typography>
    </Stack>
  );
};

export default ScheduleEvery;