import { TypeAhead } from '@platform-ui/design-system';
import React, { FC } from 'react';
import { timezones } from '../constants';

interface TimezoneProps {
  timezones: Array<{ value: string; label: string; name: string }>;
  timezone: string;
  onChange: (value: { value: string }) => void;
}

const Timezone: FC<TimezoneProps> = (props) => {
  return (
    <TypeAhead
      label='Time Zone'
      value={timezones.find((tz) => tz.value === props.timezone) || ''}
      dsOnChange={(e, value) => {
        if (value) {
          props.onChange(value);
        }
      }}
      options={props.timezones}
      getOptionLabel={(option) => option.label || ''}
      e2e='schedule-timezone-typeahead'
      placeholder='Select a timezone'
    />
  );
};

export default Timezone;