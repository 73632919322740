import { Divider } from '@mui/material';
import { Checkbox, Grid } from '@platform-ui/design-system';
import React, { FC, useEffect, useState } from 'react';
import { daysOfWeek } from '../constants';
import { RepeatsWeeklyProps } from '../interfaces';
import ScheduleTime from './ScheduleTime';

const Weekly: FC<RepeatsWeeklyProps> = (props) => {
  const [weekly, setWeekly] = useState({
    hour: '00',
    minute: '00',
    selected: daysOfWeek.map((dayOfWeek) => ({ label: dayOfWeek, value: false }))
  });

  const getWeeklySelectedDays = (selectedDays?: Array<{ label: string, value: boolean }>) => {
    const days = selectedDays || weekly.selected;
    return days.filter((day) => day.value).map((day) => day.label.substring(0, 3).toUpperCase());
  };

  useEffect(() => {
    props.onChange(['0', '00', '00', '*', '*', '*']);
  }, []);

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
      {
        weekly.selected.map((day, index) => (
          <Grid item xs={3} key={`schedule-weekly-option-${index}`}>
            <Checkbox
              label={day.label}
              dsOnChange={(e) => {
                const selected = [...weekly.selected];
                selected[index].value = e.target.checked;
                setWeekly({ ...weekly, selected });
                const cronExpression = ['0', weekly.minute, weekly.hour, '*', '*', getWeeklySelectedDays(selected).join(',')];
                props.onChange(cronExpression);
              }}
              checked={day.value}
            />
          </Grid>
        ))
      }
      </Grid>
      <Divider sx={{ margin: '16px 0' }} />
      <ScheduleTime
        label='Start time'
        hourly={weekly}
        onChange={(e, isHour) => {
          let cronExpression: string[];
          if (isHour) {
            setWeekly({ ...weekly, hour: e.target.value });
            cronExpression = ['0', weekly.minute, e.target.value, '*', '*', getWeeklySelectedDays().join(',')];
          } else {
            setWeekly({ ...weekly, minute: e.target.value });
            cronExpression = ['0', e.target.value, weekly.hour, '*', '*', getWeeklySelectedDays().join(',')];
          }
          props.onChange(cronExpression);
        }}
        repeats='weekly'
        disabled={false}
      />
    </>
  );
};

export default Weekly;