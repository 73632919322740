import { TextField } from '@mui/material';
import React, { FC } from 'react';

interface ScheduleTextFieldProps {
  value: string;
  onChange: (e: { target: { value: string }}) => void;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  error?: boolean;
}

const ScheduleTextField: FC<ScheduleTextFieldProps> = ({
  value,
  onChange,
  placeholder = '00',
  disabled = false,
  type = 'text',
  error = false
}) => {
  return (
    <TextField
      value={value}
      className='schedule-textfield'
      inputProps={{ minLength: 1 }}
      sx={{
        width: '48px',
        maxWidth: '48px',
        background: '#FFFFFF'
      }}
      type={type}
      onChange={(e) => onChange(e)}
      placeholder={placeholder || '00'}
      disabled={disabled}
      error={error}
    />
  );
};

export default ScheduleTextField;