import { Stack } from '@mui/material';
import { TextField } from '@platform-ui/design-system';
import cronstrue from 'cronstrue';
import React, { FC, useEffect, useState } from 'react';

interface IntervalProps {
  interval: string;
  showCronMessage?: boolean;
}

const Interval: FC<IntervalProps> = (props) => {
  const [cronMessage, setCronMessage] = useState('');

  useEffect(() => {
    if (props.showCronMessage && props.interval) {
      try {
        const message = cronstrue.toString(props.interval, { use24HourTimeFormat: true });
        setCronMessage(message);
      } catch (err) {
        setCronMessage('Invalid Cron Expression');
      }
    }
  }, [props.interval]);

  return (
    <Stack spacing={1} direction='column'>
      <TextField
        label='Interval'
        dsOnChange={() => {}}
        value={props.interval}
        fullWidth
        readonly
      />
      {
        props.showCronMessage &&
        <TextField
          label='Execute'
          dsOnChange={() => {}}
          value={cronMessage}
          fullWidth
          readonly
        />
      }
    </Stack>
  );
};

export default Interval;