import { Divider, Stack } from '@mui/material';
import { Radio, RadioGroup, Select, SelectItem, Typography } from '@platform-ui/design-system';
import React, { FC, useEffect, useState } from 'react';
import { daysOfWeek, daysOfWeekSequence } from '../constants';
import { RepeatsMonthlyProps } from '../interfaces';
import ScheduleTextField from './ScheduleTextField';
import ScheduleTime from './ScheduleTime';

const Monthly: FC<RepeatsMonthlyProps> = (props) => {
  const [monthly, setMonthly] = useState({
    dayOfMonth: '1',
    dayOfMonthFreq: '1',
    dayOfWeekSequence: '1',
    dayOfWeek: 'MON',
    dayOfWeekFreq: '1',
    hour: '00',
    minute: '00',
    selected: '0'
  });

  useEffect(() => {
    props.onChange(['0', '00', '00', '1', `${props.workflowCronFormat ? '' : '1'}/1`, '*']);
  }, []);

  return (
    <>
      <RadioGroup
        label={null}
        value={monthly.selected}
        dsOnChange={(e) => {
          setMonthly({ ...monthly, selected: e.target.value });
          let cronExpression: string[];
          if (e.target.value === '0') {
            cronExpression = ['0', monthly.minute, monthly.hour, monthly.dayOfMonth, `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfMonthFreq}`, '*'];
          } else {
            cronExpression = ['0', monthly.minute, monthly.hour, '*', `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfWeekFreq}`, `${monthly.dayOfWeek}#${monthly.dayOfWeekSequence}`];
          }
          props.onChange(cronExpression);
        }}
      >
        <Stack spacing={0}>
          <Radio
            label={
              <Stack spacing={1} direction='row' alignItems='center'>
                <Typography variant='body2' className='dark-text'>
                  Day
                </Typography>
                <ScheduleTextField
                  value={monthly.dayOfMonth}
                  onChange={(e) => {
                    setMonthly({ ...monthly, dayOfMonth: e.target.value });
                    const cronExpression = ['0', monthly.minute, monthly.hour, e.target.value, `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfMonthFreq}`, '*'];
                    props.onChange(cronExpression);
                  }}
                  disabled={monthly.selected !== '0'}
                  type='number'
                  error={Number.isNaN(parseInt(monthly.dayOfMonth)) || parseInt(monthly.dayOfMonth) < 1}
                />
                <Typography variant='body2' className='dark-text'>
                  of every
                </Typography>
                <ScheduleTextField
                  value={monthly.dayOfMonthFreq}
                  onChange={(e) => {
                    setMonthly({ ...monthly, dayOfMonthFreq: e.target.value });
                    const cronExpression = ['0', monthly.minute, monthly.hour, monthly.dayOfMonth, `${props.workflowCronFormat ? '' : '1'}/${e.target.value}`, '*'];
                    props.onChange(cronExpression);
                  }}
                  disabled={monthly.selected !== '0'}
                  type='number'
                  error={Number.isNaN(parseInt(monthly.dayOfMonthFreq)) || parseInt(monthly.dayOfMonthFreq) < 1}
                />
                <Typography variant='body2' className='dark-text'>
                  month(s)
                </Typography>
              </Stack>
            }
            value={0}
            e2e='schedule-radio-top'
          />
          <Radio
            label={
              <Stack spacing={1} direction='row' alignItems='center'>
                <Typography variant='body2' className='dark-text'>
                  The
                </Typography>
                <Select
                  label='first'
                  value={monthly.dayOfWeekSequence}
                  dsOnChange={(e) => {
                    setMonthly({ ...monthly, dayOfWeekSequence: e.target.value as string });
                    const cronExpression = ['0', monthly.minute, monthly.hour, '*', `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfWeekFreq}`, `${monthly.dayOfWeek}#${e.target.value}`];
                    props.onChange(cronExpression);
                  }}
                  disabled={monthly.selected !== '1'}
                  e2e='schedule-sequence-select'
                >
                {
                  daysOfWeekSequence.map((option, index) => (
                    <SelectItem value={index + 1} key={`schedule-monthly-week-sequence-option-${index}`}>
                      {option}
                    </SelectItem>
                  ))
                }
                </Select>
                <Select
                  label='day'
                  value={monthly.dayOfWeek}
                  dsOnChange={(e) => {
                    setMonthly({ ...monthly, dayOfWeek: e.target.value as string });
                    const cronExpression = ['0', monthly.minute, monthly.hour, '*', `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfWeekFreq}`, `${e.target.value}#${monthly.dayOfWeekSequence}`];
                    props.onChange(cronExpression);
                  }}
                  disabled={monthly.selected !== '1'}
                  e2e='schedule-day-1-select'
                >
                {
                  daysOfWeek.map((option, index) => (
                    <SelectItem value={option.substring(0, 3).toUpperCase()} key={`schedule-monthly-day-option-${index}`}>
                      {option}
                    </SelectItem>
                  ))
                }
                </Select>
                <Typography variant='body2' className='dark-text'>
                  of every
                </Typography>
                <ScheduleTextField
                  value={monthly.dayOfWeekFreq}
                  onChange={(e) => {
                    setMonthly({ ...monthly, dayOfWeekFreq: e.target.value });
                    const cronExpression = ['0', monthly.minute, monthly.hour, '*', `${props.workflowCronFormat ? '' : '1'}/${e.target.value}`, `${monthly.dayOfWeek}#${monthly.dayOfWeekSequence}`];
                    props.onChange(cronExpression);
                  }}
                  disabled={monthly.selected !== '1'}
                  type='number'
                  error={Number.isNaN(parseInt(monthly.dayOfWeekFreq)) || parseInt(monthly.dayOfWeekFreq) < 1}
                />
                <Typography variant='body2' className='dark-text'>
                  month(s)
                </Typography>
              </Stack>
            }
            value={1}
            e2e='schedule-monthly-radio-bottom'
          />
        </Stack>
      </RadioGroup>
      <Divider sx={{ margin: '16px 0' }} />
      <ScheduleTime
        label='Start time'
        hourly={monthly}
        onChange={(e, isHour) => {
          let cronExpression: string[];
          if (isHour) {
            setMonthly({ ...monthly, hour: e.target.value });
            if (monthly.selected === '0') {
              cronExpression = ['0', monthly.minute, e.target.value, monthly.dayOfMonth, `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfMonthFreq}`, '*'];
            } else {
              cronExpression = ['0', monthly.minute, e.target.value, '*', `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfWeekFreq}`, `${monthly.dayOfWeek}#${monthly.dayOfWeekSequence}`];
            }
          } else {
            setMonthly({ ...monthly, minute: e.target.value });
            if (monthly.selected === '0') {
              cronExpression = ['0', e.target.value, monthly.hour, monthly.dayOfMonth, `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfMonthFreq}`, '*'];
            } else {
              cronExpression = ['0', e.target.value, monthly.hour, '*', `${props.workflowCronFormat ? '' : '1'}/${monthly.dayOfWeekFreq}`, `${monthly.dayOfWeek}#${monthly.dayOfWeekSequence}`];
            }
          }
          props.onChange(cronExpression);
        }}
        repeats='monthly'
        disabled={false}
      />
    </>
  );
};

export default Monthly;