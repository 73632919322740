import { Dispatch, createContext, useContext } from 'react';
import { Action, State } from './types';

export const StoreContext = createContext<{state: State, dispatch: Dispatch<Action>} | null>(null);

export const useStoreContext = () => useContext(StoreContext);

export const useStoreState = () => {
  const { state } = useStoreContext();
  if (!state) {
    throw new Error('useStoreState must be used within StoreContext.Provider');
  }
  return state;
};

export const useStoreDispatch = () => {
  const { dispatch } = useStoreContext();
  if (!dispatch) {
    throw new Error('useStoreDispatch must be used within StoreContext.Provider');
  }
  return dispatch;
};

