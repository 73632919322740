import { Box } from '@mui/material';
import { Select, SelectItem } from '@platform-ui/design-system';
import React, { FC, useState } from 'react';
import { repeats } from '../constants';
import Minutely from './Minutely';
import Hourly from './Hourly';
import Daily from './Daily';
import Weekly from './Weekly';
import Monthly from './Monthly';
import Yearly from './Yearly';

interface ScheduleRepeatsProps {
  workflowCronFormat?: boolean;
  onChange: (cronExpression: string[]) => void;
}

const ScheduleRepeats: FC<ScheduleRepeatsProps> = (props) => {
  const [repeat, setRepeat] = useState('');

  const renderRepeats = (repeat: string) => {
    switch (repeat) {
      case 'Minutely':
        return (
          <Minutely workflowCronFormat={props.workflowCronFormat} onChange={props.onChange} />
        );
      case 'Hourly':
        return (
          <Hourly workflowCronFormat={props.workflowCronFormat} onChange={props.onChange} />
        );
      case 'Daily':
        return (
          <Daily workflowCronFormat={props.workflowCronFormat} onChange={props.onChange} />
        );
      case 'Weekly':
        return (
          <Weekly workflowCronFormat={props.workflowCronFormat} onChange={props.onChange} />
        );
      case 'Monthly':
        return (
          <Monthly workflowCronFormat={props.workflowCronFormat} onChange={props.onChange} />
        );
      case 'Yearly':
        return (
          <Yearly workflowCronFormat={props.workflowCronFormat} onChange={props.onChange} />
        );
    }
  };
  
  return (
    <>
      <Select
        label='Repeats'
        value={repeat}
        dsOnChange={(e) => setRepeat(e.target.value as string)}
      >
      {
        repeats.map((repeat, index) => (
          <SelectItem value={repeat} key={`schedule-repeats-option-${index}`}>
            {repeat}
          </SelectItem>
        ))
      }
      </Select>
      {
        repeat &&
        <Box sx={{
          padding: '16px',
          marginBottom: '8px',
          background: '#F6F7F8',
          borderRadius: '4px'
        }}>
          {renderRepeats(repeat)}
        </Box>
      }
    </>
  );
};

export default ScheduleRepeats;