import { Divider, Stack } from '@mui/material';
import { Radio, RadioGroup, Select, SelectItem, Typography } from '@platform-ui/design-system';
import React, { FC, useEffect, useState } from 'react';
import { RepeatsYearlyProps } from '../interfaces';
import ScheduleTime from './ScheduleTime';
import { daysOfWeek, daysOfWeekSequence, months } from '../constants';
import ScheduleTextField from './ScheduleTextField';

const Yearly: FC<RepeatsYearlyProps> = (props) => {
  const [yearly, setYearly] = useState({
    everyMonth: '1',
    everyMonthDayNum: '1',
    dayOfWeekSequence: '1',
    dayOfWeek: 'MON',
    dayOfWeekMonth: '1',
    hour: '00',
    minute: '00',
    selected: '0'
  });

  useEffect(() => {
    props.onChange(['0', '00', '00', '1', '1', '*']);
  }, []);

  return (
    <>
      <RadioGroup
        label={null}
        value={yearly.selected}
        dsOnChange={(e) => {
          setYearly({ ...yearly, selected: e.target.value });
          let cronExpression: string[];
          if (e.target.value === '0') {
            cronExpression = ['0', yearly.minute, yearly.hour, yearly.everyMonthDayNum, yearly.everyMonth, '*'];
          } else {
            cronExpression = ['0', yearly.minute, yearly.hour, '*', yearly.dayOfWeekMonth, `${yearly.dayOfWeek}#${yearly.dayOfWeekSequence}`];
          }
          props.onChange(cronExpression);
        }}
      >
        <Stack spacing={0}>
          <Radio
            label={
              <Stack spacing={1} direction='row' alignItems='center'>
                <Typography variant='body2' className='dark-text'>
                  Every
                </Typography>
                <Select
                  label='month'
                  value={yearly.everyMonth}
                  dsOnChange={(e) => {
                    setYearly({ ...yearly, everyMonth: e.target.value as string });
                    const cronExpression = ['0', yearly.minute, yearly.hour, yearly.everyMonthDayNum, e.target.value as string, '*'];
                    props.onChange(cronExpression);
                  }}
                  disabled={yearly.selected !== '0'}
                  e2e='schedule-month-1-select'
                >
                {
                  months.map((option, index) => (
                    <SelectItem value={index + 1} key={`schedule-yearly-month-option-${index}`}>
                      {option}
                    </SelectItem>
                  ))
                }
                </Select>
                <Typography variant='body2' className='dark-text'>
                  on day
                </Typography>
                <ScheduleTextField
                  value={yearly.everyMonthDayNum}
                  onChange={(e) => {
                    setYearly({ ...yearly, everyMonthDayNum: e.target.value });
                    const cronExpression = ['0', yearly.minute, yearly.hour, e.target.value, yearly.everyMonth, '*'];
                    props.onChange(cronExpression);
                  }}
                  disabled={yearly.selected !== '0'}
                  type='number'
                  error={Number.isNaN(parseInt(yearly.everyMonthDayNum)) || parseInt(yearly.everyMonthDayNum) < 1}
                />
              </Stack>
            }
            value={0}
            e2e='schedule-radio-top'
          />
          <Radio
            label={
              <Stack spacing={1} direction='row' alignItems='center'>
                <Typography variant='body2' className='dark-text'>
                  The
                </Typography>
                <Select
                  label='first'
                  value={yearly.dayOfWeekSequence}
                  dsOnChange={(e) => {
                    setYearly({ ...yearly, dayOfWeekSequence: e.target.value as string });
                    const cronExpression = ['0', yearly.minute, yearly.hour, '*', yearly.dayOfWeekMonth, `${yearly.dayOfWeek}#${e.target.value}`];
                    props.onChange(cronExpression);
                  }}
                  disabled={yearly.selected !== '1'}
                  e2e='schedule-sequence-select'
                >
                {
                  daysOfWeekSequence.map((option, index) => (
                    <SelectItem value={index + 1} key={`schedule-yearly-hour-option-${index}`}>
                      {option}
                    </SelectItem>
                  ))
                }
                </Select>
                <Select
                  label='day'
                  value={yearly.dayOfWeek}
                  dsOnChange={(e) => {
                    setYearly({ ...yearly, dayOfWeek: e.target.value as string});
                    const cronExpression = ['0', yearly.minute, yearly.hour, '*', yearly.dayOfWeekMonth, `${e.target.value}#${yearly.dayOfWeekSequence}`];
                    props.onChange(cronExpression);
                  }}
                  disabled={yearly.selected !== '1'}
                  e2e='schedule-day-2-select'
                >
                {
                  daysOfWeek.map((option, index) => (
                    <SelectItem value={option.substring(0, 3).toUpperCase()} key={`schedule-yearly-day-2-option-${index}`}>
                      {option}
                    </SelectItem>
                  ))
                }
                </Select>
                <Typography variant='body2' className='dark-text'>
                  of
                </Typography>
                <Select
                  label='month'
                  value={yearly.dayOfWeekMonth}
                  dsOnChange={(e) => {
                    setYearly({ ...yearly, dayOfWeekMonth: e.target.value as string });
                    const cronExpression = ['0', yearly.minute, yearly.hour, '*', e.target.value as string, `${yearly.dayOfWeek}#${yearly.dayOfWeekSequence}`];
                    props.onChange(cronExpression);
                  }}
                  disabled={yearly.selected !== '1'}
                  e2e='schedule-month-2-select'
                >
                {
                  months.map((option, index) => (
                    <SelectItem value={index + 1} key={`schedule-yearly-month-2-option-${index}`}>
                      {option}
                    </SelectItem>
                  ))
                }  
                </Select>
              </Stack>
            }
            value={1}
          />
        </Stack>
      </RadioGroup>
      <Divider sx={{ margin: '16px 0' }} />
      <ScheduleTime
        label='Start time'
        hourly={yearly}
        onChange={(e, isHour) => {
          let cronExpression: string[];
          if (isHour) {
            setYearly({ ...yearly, hour: e.target.value });
            if (yearly.selected === '0') {
              cronExpression = ['0', yearly.minute, e.target.value, yearly.everyMonthDayNum, yearly.everyMonth, '*'];
            } else {
              cronExpression = ['0', yearly.minute, e.target.value, '*', yearly.dayOfWeekMonth, `${yearly.dayOfWeek}#${yearly.dayOfWeekSequence}`];
            }
          } else {
            setYearly({ ...yearly, minute: e.target.value });
            if (yearly.selected === '0') {
              cronExpression = ['0', e.target.value, yearly.hour, yearly.everyMonthDayNum, yearly.everyMonth, '*'];
            } else {
              cronExpression = ['0', e.target.value, yearly.hour, '*', yearly.dayOfWeekMonth, `${yearly.dayOfWeek}#${yearly.dayOfWeekSequence}`];
            }
          }
          props.onChange(cronExpression);
        }}
        repeats='yearly'
        disabled={false}
      />
    </>
  );
};

export default Yearly;