import { Stack } from '@mui/material';
import { Select, SelectItem, Typography } from '@platform-ui/design-system';
import React, { FC } from 'react';
import { hours, minutes } from '../constants';

interface ScheduleTimeProps {
  label: string;
  repeats: string;
  hourly: { hour: string, minute: string };
  onChange: (e: { target: { value: string } }, isHour: boolean) => void;
  disabled: boolean;
}

const ScheduleTime: FC<ScheduleTimeProps> = (props) => {
  return (
    <Stack spacing={1} direction='row' alignItems='center'>
      <Typography variant='body2' className='dark-text'>
        {props.label}
      </Typography>
      <Select
        label='hour'
        value={props.hourly.hour}
        dsOnChange={(e: any) => props.onChange(e, true)}
        disabled={props.disabled}
        e2e={`schedule-time-hour-select`}
        sx={{ background: '#FFFFFF' }}
        fullWidth={false}
      >
      {
        hours.map((option, index) => (
          <SelectItem value={option} key={`${props.repeats}-hour-option-${index}`}>
            {option}
          </SelectItem>
        ))
      }
      </Select>
      <Typography variant='body2' className='dark-text'>
        :
      </Typography>
      <Select
        label='minute'
        value={props.hourly.minute}
        dsOnChange={(e: any) => props.onChange(e, false)}
        disabled={props.disabled}
        e2e={`schedule-time-minute-select`}
        sx={{ background: '#FFFFFF' }}
        fullWidth={false}
      >
      {
        minutes.map((option, index) => (
          <SelectItem value={option} key={`${props.repeats}-minute-option-${index}`}>
            {option}
          </SelectItem>
        ))
      }
      </Select>
    </Stack>
  );
};

export default ScheduleTime;