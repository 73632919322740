import { Stack } from '@mui/material';
import { Typography } from '@platform-ui/design-system';
import React, { FC, useEffect, useState } from 'react';
import { RepeatsMinutelyProps } from '../interfaces';
import ScheduleTextField from './ScheduleTextField';

const Minutely: FC<RepeatsMinutelyProps> = (props) => {
  const [minutes, setMinutes] = useState('1');
  const onMinuteChange = (e: { target: { value: string } }) => {
    const value = parseInt(e.target.value);
    if (value > 0 || e.target.value === '') {
      setMinutes(e.target.value);
      const cronExpression = ['0', `${props.workflowCronFormat ? '' : '0'}/${e.target.value}`, '*', `${props.workflowCronFormat ? '/1' : '*'}`, '*', '*'];
      props.onChange(cronExpression);
    }
  };

  useEffect(() => {
    props.onChange(['0', `${props.workflowCronFormat ? '' : '0'}/1`, '*', `${props.workflowCronFormat ? '/1' : '*'}`, '*', '*']);
  }, []);

  return (
    <Stack spacing={1} direction='row' alignItems='center'>
      <Typography variant='body2' e2e='dark-text'>
        Every
      </Typography>
      <ScheduleTextField
        value={minutes}
        onChange={onMinuteChange}
        disabled={false}
        placeholder='1'
        type='number'
        error={Number.isNaN(parseInt(minutes)) || parseInt(minutes) < 1}
      />
      <Typography variant='body2' className='dark-text'>
        minute(s)
      </Typography>
    </Stack>
  );
};

export default Minutely;