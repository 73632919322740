export const hours = Array.from(Array(24), (e, index) => (index < 10 ? '0' + index : index));

export const minutes = Array.from(Array(60), (e, index) => (index < 10 ? '0' + index : index));

export const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

export const daysOfWeekSequence = ['First', 'Second', 'Third', 'Fourth'];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const repeats = ['Minutely', 'Hourly', 'Daily', 'Weekly', 'Monthly', 'Yearly'];

export const timezones = [
  {
    "value": "International Date Line West",
    "label": "(GMT-12:00) International Date Line West",
    "name": "Etc/GMT+12"
  },
  {
    "value": "American Samoa",
    "label": "(GMT-11:00) American Samoa",
    "name": "Pacific/Pago_Pago"
  },
  {
    "value": "Midway Island",
    "label": "(GMT-11:00) Midway Island",
    "name": "Pacific/Midway"
  },
  {
    "value": "Hawaii",
    "label": "(GMT-10:00) Hawaii",
    "name": "Pacific/Honolulu"
  },
  {
    "value": "Alaska",
    "label": "(GMT-09:00) Alaska",
    "name": "America/Juneau"
  },
  {
    "value": "Pacific Time (US & Canada)",
    "label": "(GMT-08:00) Pacific Time (US & Canada)",
    "name": "America/Los_Angeles"
  },
  {
    "value": "Tijuana",
    "label": "(GMT-08:00) Tijuana",
    "name": "America/Tijuana"
  },
  {
    "value": "Arizona",
    "label": "(GMT-07:00) Arizona",
    "name": "America/Phoenix"
  },
  {
    "value": "Mazatlan",
    "label": "(GMT-07:00) Mazatlan",
    "name": "America/Mazatlan"
  },
  {
    "value": "Mountain Time (US & Canada)",
    "label": "(GMT-07:00) Mountain Time (US & Canada)",
    "name": "America/Denver"
  },
  {
    "value": "Central America",
    "label": "(GMT-06:00) Central America",
    "name": "America/Guatemala"
  },
  {
    "value": "Central Time (US & Canada)",
    "label": "(GMT-06:00) Central Time (US & Canada)",
    "name": "America/Chicago"
  },
  {
    "value": "Chihuahua",
    "label": "(GMT-06:00) Chihuahua",
    "name": "America/Chihuahua"
  },
  {
    "value": "Guadalajara",
    "label": "(GMT-06:00) Guadalajara",
    "name": "America/Mexico_City"
  },
  {
    "value": "Mexico City",
    "label": "(GMT-06:00) Mexico City",
    "name": "America/Mexico_City"
  },
  {
    "value": "Monterrey",
    "label": "(GMT-06:00) Monterrey",
    "name": "America/Monterrey"
  },
  {
    "value": "Saskatchewan",
    "label": "(GMT-06:00) Saskatchewan",
    "name": "America/Regina"
  },
  {
    "value": "Bogota",
    "label": "(GMT-05:00) Bogota",
    "name": "America/Bogota"
  },
  {
    "value": "Eastern Time (US & Canada)",
    "label": "(GMT-05:00) Eastern Time (US & Canada)",
    "name": "America/New_York"
  },
  {
    "value": "Indiana (East)",
    "label": "(GMT-05:00) Indiana (East)",
    "name": "America/Indiana/Indianapolis"
  },
  {
    "value": "Lima",
    "label": "(GMT-05:00) Lima",
    "name": "America/Lima"
  },
  {
    "value": "Quito",
    "label": "(GMT-05:00) Quito",
    "name": "America/Lima"
  },
  {
    "value": "Atlantic Time (Canada)",
    "label": "(GMT-04:00) Atlantic Time (Canada)",
    "name": "America/Halifax"
  },
  {
    "value": "Caracas",
    "label": "(GMT-04:00) Caracas",
    "name": "America/Caracas"
  },
  {
    "value": "Georgetown",
    "label": "(GMT-04:00) Georgetown",
    "name": "America/Guyana"
  },
  {
    "value": "La Paz",
    "label": "(GMT-04:00) La Paz",
    "name": "America/La_Paz"
  },
  {
    "value": "Puerto Rico",
    "label": "(GMT-04:00) Puerto Rico",
    "name": "America/Puerto_Rico"
  },
  {
    "value": "Santiago",
    "label": "(GMT-04:00) Santiago",
    "name": "America/Santiago"
  },
  {
    "value": "Newfoundland",
    "label": "(GMT-03:30) Newfoundland",
    "name": "America/St_Johns"
  },
  {
    "value": "Brasilia",
    "label": "(GMT-03:00) Brasilia",
    "name": "America/Sao_Paulo"
  },
  {
    "value": "Buenos Aires",
    "label": "(GMT-03:00) Buenos Aires",
    "name": "America/Argentina/Buenos_Aires"
  },
  {
    "value": "Greenland",
    "label": "(GMT-03:00) Greenland",
    "name": "America/Godthab"
  },
  {
    "value": "Montevideo",
    "label": "(GMT-03:00) Montevideo",
    "name": "America/Montevideo"
  },
  {
    "value": "Mid-Atlantic",
    "label": "(GMT-02:00) Mid-Atlantic",
    "name": "Atlantic/South_Georgia"
  },
  {
    "value": "Azores",
    "label": "(GMT-01:00) Azores",
    "name": "Atlantic/Azores"
  },
  {
    "value": "Cape Verde Is.",
    "label": "(GMT-01:00) Cape Verde Is.",
    "name": "Atlantic/Cape_Verde"
  },
  {
    "value": "Edinburgh",
    "label": "(GMT+00:00) Edinburgh",
    "name": "Europe/London"
  },
  {
    "value": "Lisbon",
    "label": "(GMT+00:00) Lisbon",
    "name": "Europe/Lisbon"
  },
  {
    "value": "London",
    "label": "(GMT+00:00) London",
    "name": "Europe/London"
  },
  {
    "value": "Monrovia",
    "label": "(GMT+00:00) Monrovia",
    "name": "Africa/Monrovia"
  },
  {
    "value": "UTC",
    "label": "(GMT+00:00) UTC",
    "name": "Etc/UTC"
  },
  {
    "value": "Amsterdam",
    "label": "(GMT+01:00) Amsterdam",
    "name": "Europe/Amsterdam"
  },
  {
    "value": "Belgrade",
    "label": "(GMT+01:00) Belgrade",
    "name": "Europe/Belgrade"
  },
  {
    "value": "Berlin",
    "label": "(GMT+01:00) Berlin",
    "name": "Europe/Berlin"
  },
  {
    "value": "Bern",
    "label": "(GMT+01:00) Bern",
    "name": "Europe/Zurich"
  },
  {
    "value": "Bratislava",
    "label": "(GMT+01:00) Bratislava",
    "name": "Europe/Bratislava"
  },
  {
    "value": "Brussels",
    "label": "(GMT+01:00) Brussels",
    "name": "Europe/Brussels"
  },
  {
    "value": "Budapest",
    "label": "(GMT+01:00) Budapest",
    "name": "Europe/Budapest"
  },
  {
    "value": "Casablanca",
    "label": "(GMT+01:00) Casablanca",
    "name": "Africa/Casablanca"
  },
  {
    "value": "Copenhagen",
    "label": "(GMT+01:00) Copenhagen",
    "name": "Europe/Copenhagen"
  },
  {
    "value": "Dublin",
    "label": "(GMT+01:00) Dublin",
    "name": "Europe/Dublin"
  },
  {
    "value": "Ljubljana",
    "label": "(GMT+01:00) Ljubljana",
    "name": "Europe/Ljubljana"
  },
  {
    "value": "Madrid",
    "label": "(GMT+01:00) Madrid",
    "name": "Europe/Madrid"
  },
  {
    "value": "Paris",
    "label": "(GMT+01:00) Paris",
    "name": "Europe/Paris"
  },
  {
    "value": "Prague",
    "label": "(GMT+01:00) Prague",
    "name": "Europe/Prague"
  },
  {
    "value": "Rome",
    "label": "(GMT+01:00) Rome",
    "name": "Europe/Rome"
  },
  {
    "value": "Sarajevo",
    "label": "(GMT+01:00) Sarajevo",
    "name": "Europe/Sarajevo"
  },
  {
    "value": "Skopje",
    "label": "(GMT+01:00) Skopje",
    "name": "Europe/Skopje"
  },
  {
    "value": "Stockholm",
    "label": "(GMT+01:00) Stockholm",
    "name": "Europe/Stockholm"
  },
  {
    "value": "Vienna",
    "label": "(GMT+01:00) Vienna",
    "name": "Europe/Vienna"
  },
  {
    "value": "Warsaw",
    "label": "(GMT+01:00) Warsaw",
    "name": "Europe/Warsaw"
  },
  {
    "value": "West Central Africa",
    "label": "(GMT+01:00) West Central Africa",
    "name": "Africa/Algiers"
  },
  {
    "value": "Zagreb",
    "label": "(GMT+01:00) Zagreb",
    "name": "Europe/Zagreb"
  },
  {
    "value": "Zurich",
    "label": "(GMT+01:00) Zurich",
    "name": "Europe/Zurich"
  },
  {
    "value": "Athens",
    "label": "(GMT+02:00) Athens",
    "name": "Europe/Athens"
  },
  {
    "value": "Bucharest",
    "label": "(GMT+02:00) Bucharest",
    "name": "Europe/Bucharest"
  },
  {
    "value": "Cairo",
    "label": "(GMT+02:00) Cairo",
    "name": "Africa/Cairo"
  },
  {
    "value": "Harare",
    "label": "(GMT+02:00) Harare",
    "name": "Africa/Harare"
  },
  {
    "value": "Helsinki",
    "label": "(GMT+02:00) Helsinki",
    "name": "Europe/Helsinki"
  },
  {
    "value": "Jerusalem",
    "label": "(GMT+02:00) Jerusalem",
    "name": "Asia/Jerusalem"
  },
  {
    "value": "Kaliningrad",
    "label": "(GMT+02:00) Kaliningrad",
    "name": "Europe/Kaliningrad"
  },
  {
    "value": "Kyiv",
    "label": "(GMT+02:00) Kyiv",
    "name": "Europe/Kiev"
  },
  {
    "value": "Pretoria",
    "label": "(GMT+02:00) Pretoria",
    "name": "Africa/Johannesburg"
  },
  {
    "value": "Riga",
    "label": "(GMT+02:00) Riga",
    "name": "Europe/Riga"
  },
  {
    "value": "Sofia",
    "label": "(GMT+02:00) Sofia",
    "name": "Europe/Sofia"
  },
  {
    "value": "Tallinn",
    "label": "(GMT+02:00) Tallinn",
    "name": "Europe/Tallinn"
  },
  {
    "value": "Vilnius",
    "label": "(GMT+02:00) Vilnius",
    "name": "Europe/Vilnius"
  },
  {
    "value": "Baghdad",
    "label": "(GMT+03:00) Baghdad",
    "name": "Asia/Baghdad"
  },
  {
    "value": "Istanbul",
    "label": "(GMT+03:00) Istanbul",
    "name": "Europe/Istanbul"
  },
  {
    "value": "Kuwait",
    "label": "(GMT+03:00) Kuwait",
    "name": "Asia/Kuwait"
  },
  {
    "value": "Minsk",
    "label": "(GMT+03:00) Minsk",
    "name": "Europe/Minsk"
  },
  {
    "value": "Moscow",
    "label": "(GMT+03:00) Moscow",
    "name": "Europe/Moscow"
  },
  {
    "value": "Nairobi",
    "label": "(GMT+03:00) Nairobi",
    "name": "Africa/Nairobi"
  },
  {
    "value": "Riyadh",
    "label": "(GMT+03:00) Riyadh",
    "name": "Asia/Riyadh"
  },
  {
    "value": "St. Petersburg",
    "label": "(GMT+03:00) St. Petersburg",
    "name": "Europe/Moscow"
  },
  {
    "value": "Volgograd",
    "label": "(GMT+03:00) Volgograd",
    "name": "Europe/Volgograd"
  },
  {
    "value": "Tehran",
    "label": "(GMT+03:30) Tehran",
    "name": "Asia/Tehran"
  },
  {
    "value": "Abu Dhabi",
    "label": "(GMT+04:00) Abu Dhabi",
    "name": "Asia/Muscat"
  },
  {
    "value": "Baku",
    "label": "(GMT+04:00) Baku",
    "name": "Asia/Baku"
  },
  {
    "value": "Muscat",
    "label": "(GMT+04:00) Muscat",
    "name": "Asia/Muscat"
  },
  {
    "value": "Samara",
    "label": "(GMT+04:00) Samara",
    "name": "Europe/Samara"
  },
  {
    "value": "Tbilisi",
    "label": "(GMT+04:00) Tbilisi",
    "name": "Asia/Tbilisi"
  },
  {
    "value": "Yerevan",
    "label": "(GMT+04:00) Yerevan",
    "name": "Asia/Yerevan"
  },
  {
    "value": "Kabul",
    "label": "(GMT+04:30) Kabul",
    "name": "Asia/Kabul"
  },
  {
    "value": "Ekaterinburg",
    "label": "(GMT+05:00) Ekaterinburg",
    "name": "Asia/Yekaterinburg"
  },
  {
    "value": "Islamabad",
    "label": "(GMT+05:00) Islamabad",
    "name": "Asia/Karachi"
  },
  {
    "value": "Karachi",
    "label": "(GMT+05:00) Karachi",
    "name": "Asia/Karachi"
  },
  {
    "value": "Tashkent",
    "label": "(GMT+05:00) Tashkent",
    "name": "Asia/Tashkent"
  },
  {
    "value": "Chennai",
    "label": "(GMT+05:30) Chennai",
    "name": "Asia/Kolkata"
  },
  {
    "value": "Kolkata",
    "label": "(GMT+05:30) Kolkata",
    "name": "Asia/Kolkata"
  },
  {
    "value": "Mumbai",
    "label": "(GMT+05:30) Mumbai",
    "name": "Asia/Kolkata"
  },
  {
    "value": "New Delhi",
    "label": "(GMT+05:30) New Delhi",
    "name": "Asia/Kolkata"
  },
  {
    "value": "Sri Jayawardenepura",
    "label": "(GMT+05:30) Sri Jayawardenepura",
    "name": "Asia/Colombo"
  },
  {
    "value": "Kathmandu",
    "label": "(GMT+05:45) Kathmandu",
    "name": "Asia/Kathmandu"
  },
  {
    "value": "Almaty",
    "label": "(GMT+06:00) Almaty",
    "name": "Asia/Almaty"
  },
  {
    "value": "Astana",
    "label": "(GMT+06:00) Astana",
    "name": "Asia/Dhaka"
  },
  {
    "value": "Dhaka",
    "label": "(GMT+06:00) Dhaka",
    "name": "Asia/Dhaka"
  },
  {
    "value": "Urumqi",
    "label": "(GMT+06:00) Urumqi",
    "name": "Asia/Urumqi"
  },
  {
    "value": "Rangoon",
    "label": "(GMT+06:30) Rangoon",
    "name": "Asia/Rangoon"
  },
  {
    "value": "Bangkok",
    "label": "(GMT+07:00) Bangkok",
    "name": "Asia/Bangkok"
  },
  {
    "value": "Hanoi",
    "label": "(GMT+07:00) Hanoi",
    "name": "Asia/Bangkok"
  },
  {
    "value": "Jakarta",
    "label": "(GMT+07:00) Jakarta",
    "name": "Asia/Jakarta"
  },
  {
    "value": "Krasnoyarsk",
    "label": "(GMT+07:00) Krasnoyarsk",
    "name": "Asia/Krasnoyarsk"
  },
  {
    "value": "Novosibirsk",
    "label": "(GMT+07:00) Novosibirsk",
    "name": "Asia/Novosibirsk"
  },
  {
    "value": "Beijing",
    "label": "(GMT+08:00) Beijing",
    "name": "Asia/Shanghai"
  },
  {
    "value": "Chongqing",
    "label": "(GMT+08:00) Chongqing",
    "name": "Asia/Chongqing"
  },
  {
    "value": "Hong Kong",
    "label": "(GMT+08:00) Hong Kong",
    "name": "Asia/Hong_Kong"
  },
  {
    "value": "Irkutsk",
    "label": "(GMT+08:00) Irkutsk",
    "name": "Asia/Irkutsk"
  },
  {
    "value": "Kuala Lumpur",
    "label": "(GMT+08:00) Kuala Lumpur",
    "name": "Asia/Kuala_Lumpur"
  },
  {
    "value": "Perth",
    "label": "(GMT+08:00) Perth",
    "name": "Australia/Perth"
  },
  {
    "value": "Singapore",
    "label": "(GMT+08:00) Singapore",
    "name": "Asia/Singapore"
  },
  {
    "value": "Taipei",
    "label": "(GMT+08:00) Taipei",
    "name": "Asia/Taipei"
  },
  {
    "value": "Ulaanbaatar",
    "label": "(GMT+08:00) Ulaanbaatar",
    "name": "Asia/Ulaanbaatar"
  },
  {
    "value": "Osaka",
    "label": "(GMT+09:00) Osaka",
    "name": "Asia/Tokyo"
  },
  {
    "value": "Sapporo",
    "label": "(GMT+09:00) Sapporo",
    "name": "Asia/Tokyo"
  },
  {
    "value": "Seoul",
    "label": "(GMT+09:00) Seoul",
    "name": "Asia/Seoul"
  },
  {
    "value": "Tokyo",
    "label": "(GMT+09:00) Tokyo",
    "name": "Asia/Tokyo"
  },
  {
    "value": "Yakutsk",
    "label": "(GMT+09:00) Yakutsk",
    "name": "Asia/Yakutsk"
  },
  {
    "value": "Adelaide",
    "label": "(GMT+09:30) Adelaide",
    "name": "Australia/Adelaide"
  },
  {
    "value": "Darwin",
    "label": "(GMT+09:30) Darwin",
    "name": "Australia/Darwin"
  },
  {
    "value": "Brisbane",
    "label": "(GMT+10:00) Brisbane",
    "name": "Australia/Brisbane"
  },
  {
    "value": "Canberra",
    "label": "(GMT+10:00) Canberra",
    "name": "Australia/Melbourne"
  },
  {
    "value": "Guam",
    "label": "(GMT+10:00) Guam",
    "name": "Pacific/Guam"
  },
  {
    "value": "Hobart",
    "label": "(GMT+10:00) Hobart",
    "name": "Australia/Hobart"
  },
  {
    "value": "Melbourne",
    "label": "(GMT+10:00) Melbourne",
    "name": "Australia/Melbourne"
  },
  {
    "value": "Port Moresby",
    "label": "(GMT+10:00) Port Moresby",
    "name": "Pacific/Port_Moresby"
  },
  {
    "value": "Sydney",
    "label": "(GMT+10:00) Sydney",
    "name": "Australia/Sydney"
  },
  {
    "value": "Vladivostok",
    "label": "(GMT+10:00) Vladivostok",
    "name": "Asia/Vladivostok"
  },
  {
    "value": "Magadan",
    "label": "(GMT+11:00) Magadan",
    "name": "Asia/Magadan"
  },
  {
    "value": "New Caledonia",
    "label": "(GMT+11:00) New Caledonia",
    "name": "Pacific/Noumea"
  },
  {
    "value": "Solomon Is.",
    "label": "(GMT+11:00) Solomon Is.",
    "name": "Pacific/Guadalcanal"
  },
  {
    "value": "Srednekolymsk",
    "label": "(GMT+11:00) Srednekolymsk",
    "name": "Asia/Srednekolymsk"
  },
  {
    "value": "Auckland",
    "label": "(GMT+12:00) Auckland",
    "name": "Pacific/Auckland"
  },
  {
    "value": "Fiji",
    "label": "(GMT+12:00) Fiji",
    "name": "Pacific/Fiji"
  },
  {
    "value": "Kamchatka",
    "label": "(GMT+12:00) Kamchatka",
    "name": "Asia/Kamchatka"
  },
  {
    "value": "Marshall Is.",
    "label": "(GMT+12:00) Marshall Is.",
    "name": "Pacific/Majuro"
  },
  {
    "value": "Wellington",
    "label": "(GMT+12:00) Wellington",
    "name": "Pacific/Auckland"
  },
  {
    "value": "Chatham Is.",
    "label": "(GMT+12:45) Chatham Is.",
    "name": "Pacific/Chatham"
  },
  {
    "value": "Nuku'alofa",
    "label": "(GMT+13:00) Nuku'alofa",
    "name": "Pacific/Tongatapu"
  },
  {
    "value": "Samoa",
    "label": "(GMT+13:00) Samoa",
    "name": "Pacific/Apia"
  },
  {
    "value": "Tokelau Is.",
    "label": "(GMT+13:00) Tokelau Is.",
    "name": "Pacific/Fakaofo"
  }
];