import { Stack } from '@mui/material';
import { Radio, RadioGroup } from '@platform-ui/design-system';
import React, { FC, useEffect, useState } from 'react';
import { RepeatsHourlyProps } from '../interfaces';
import ScheduleEvery from './ScheduleEvery';
import ScheduleTime from './ScheduleTime';

const Hourly: FC<RepeatsHourlyProps> = (props) => {
  const [hourly, setHourly] = useState({
    every: '1',
    hour: '00',
    minute: '00',
    selected: '0'
  });

  useEffect(() => {
    props.onChange(['0', '0', `${props.workflowCronFormat ? '' : '0'}/1`, `${props.workflowCronFormat ? '' : '1'}/1`, '*', '*']);
  }, []);

  return (
    <RadioGroup
      label={null}
      value={hourly.selected}
      dsOnChange={(e) => {
        setHourly({ ...hourly, selected: e.target.value });
        let cronExpression: string[];
        if (e.target.value === '0') {
          cronExpression = ['0', '0', `${props.workflowCronFormat ? '' : '0'}/${hourly.every}`, `${props.workflowCronFormat ? '' : '1'}/1`, '*', '*'];  
        } else {
          cronExpression = ['0', hourly.minute, hourly.hour, `${props.workflowCronFormat ? '' : '1'}/1`, '*', '*'];
        }
        props.onChange(cronExpression);
      }}
    >
      <Stack spacing={0}>
        <Radio
          label={
            <ScheduleEvery
              frontLabel='Every'
              backLabel='hour(s)'
              value={hourly.every}
              onChange={(e) => {
                setHourly({ ...hourly, every: e.target.value });
                const cronExpression = ['0', '0', `${props.workflowCronFormat ? '' : '0'}/${e.target.value}`, `${props.workflowCronFormat ? '' : '1'}/1`, '*', '*'];
                props.onChange(cronExpression);
              }}
              disabled={hourly.selected !== '0'}
              type='number'
              error={Number.isNaN(parseInt(hourly.every)) || parseInt(hourly.every) < 1}
            />
          }
          value={0}
          e2e='schedule-radio-top'
        />
        <Radio
          label={
            <ScheduleTime
              label='At'
              hourly={hourly}
              onChange={(e, isHour) => {
                let cronExpression: string[];
                if (isHour) {
                  setHourly({ ...hourly, hour: e.target.value });
                  cronExpression = ['0', hourly.minute, e.target.value, `${props.workflowCronFormat ? '' : '1'}/1`, '*', '*'];
                } else {
                  setHourly({ ...hourly, minute: e.target.value });
                  cronExpression = ['0', e.target.value, hourly.hour, `${props.workflowCronFormat ? '' : '1'}/1`, '*', '*'];
                }
                props.onChange(cronExpression);
              }}
              repeats='hourly'
              disabled={hourly.selected !== '1'}
            />
          }
          value={1}
        />
      </Stack>
    </RadioGroup>
  );
};

export default Hourly;